.tabBtns{
    text-align: left !important;
    padding-left: 0px !important;
}

.scrollbar::-webkit-scrollbar {
    display: none;
}

.textBottomMargin{
    margin-bottom: 0px !important;
}
@media only screen and (max-width:1080px){
    .tabs{
        padding-left: 0px;
        font-size: 10px;
        border-left: none !important;
    }
}