.analytics-header {
    margin-bottom: 1rem;
  }
  
  .analytics-actions {
    margin-top: 1rem;
  }
  
  .action-button {
    cursor: pointer;
    color: #3182ce;
    font-weight: bold;
    margin-right: 1rem;
  }
  
  .action-button:hover {
    text-decoration: underline;
  }
  
  .chart-box {
    flex: 1;
    min-width: 300px;
    margin: 1rem;
    background: #f9f9f9;
    border-radius: 8px;
    padding: 1rem;
    transition: box-shadow 0.3s, transform 0.3s;
  }
  
  .chart-box:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
  }
  
  .chart-box canvas {
    display: block;
  }
  