.raise-ticket-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: linear-gradient(135deg, #6d31ed, #31bced);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: auto;
    color: white;
    animation: fadeIn 1s ease-in-out;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .ticket-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  
  .ticket-form label {
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
  }
  
  .ticket-form input,
  .ticket-form textarea {
    padding: 10px;
    border-radius: 5px;
    border: none;
    color: black;
    margin-top: 5px;
  }
  
  .ticket-form button {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #31bced;
    color: white;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .ticket-form button:hover {
    background-color: #1a85c8;
  }
  
  .thank-you-message {
    text-align: center;
    animation: slideIn 1s ease-in-out;
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .social-media-share {
    margin-top: 20px;
    text-align: center;
  }
  
  .icons {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
  
  .icons svg {
    font-size: 2em;
    cursor: pointer;
    transition: transform 0.3s;
  }
  
  .icons svg:hover {
    transform: scale(1.2);
  }
