.analytics-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #e2e8f0;
  padding-bottom: 16px;
  margin-bottom: 24px;
  font-family: 'Roboto', sans-serif;
}

.analytics-header h1 {
  font-size: 1.75rem;
  font-weight: 600;
  color: #2d3748;
}

.analytics-actions {
  display: flex;
  align-items: center;
}

.analytics-actions > .action-button {
  color: #3182ce;
  cursor: pointer;
  font-weight: 500;
  margin-left: 16px;
  transition: color 0.3s ease;
}

.analytics-actions > .action-button:hover {
  color: #2b6cb0;
  text-decoration: underline;
}

.chart-container {
  width: 100%;
  margin: 0 -24px;
}

.chart-box {
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin: 20px 0;
  transition: transform 0.3s, box-shadow 0.3s;
}

.chart-box:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .analytics-header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .analytics-header h1 {
    font-size: 1.5rem;
  }

  .analytics-actions {
    margin-top: 12px;
  }

  .analytics-actions > .action-button {
    margin-left: 0;
    margin-right: 12px;
    font-size: 0.9rem;
  }

  .chart-box {
    margin: 15px 10px;
    padding: 18px;
  }

  .chart-container {
    margin: 0;
  }
}

