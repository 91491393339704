.SearchInputBox:focus{
    box-shadow: none !important;
   
}
.textBottomMargin{
    margin-bottom: 0px !important;
}

.selectBoxBorder{
    border-radius: 0px !important;
}
.boxHeight{
    height: auto !important;
}
