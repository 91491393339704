.totalBudget{
    margin-bottom: 0px !important;
}

.dailyBudgetDays{
    font-size: 18px;
    font-weight: bold;
}
.dailyBudgetPrice{
    font-size: 50px;
    font-weight: 600;
    color: #000000;
}
.CampaignName{
    font-size: 18px;
    color: red;
    font-weight: 500;
    margin-bottom: 0px !important;

}
.CampaignObjective{
    font-size: 16px;
    color: #000000;
    font-weight: 500;
    margin-bottom: 0px !important;

}
.CampaignOptions{
    font-size: 16px;
    color: gray;
    font-weight: 600;
}

