.analytics-header {
    margin-bottom: 20px;
  }
  
  .analytics-actions {
    margin-bottom: 20px;
  }
  
  .action-button {
    cursor: pointer;
    background-color: #E1306C;
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin-right: 10px;
    text-align: center;
    transition: background-color 0.3s;
  }
  
  .action-button:hover {
    background-color: #B72A5A;
  }
  
  .chart-box {
    flex: 1;
    min-width: 300px;
    padding: 16px;
    background-color: #F8F9FA;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    margin: 10px;
  }
  