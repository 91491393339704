
.table-header-style {
  text-align: center;
  font-weight: 500;
  text-transform: none;
  font-size: 14px;
  line-height: 21px;
  color: #262A2A;
  font-family:Noto Sans;
}

.chakra-form__label {
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  color: #000000CC;
}

