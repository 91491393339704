.app {
    display: flex;
  }
  
  .sidebar {
    width: 250px;
    height: 100vh;
    background-color: #2c3e50;
    color: white;
    transition: width 0.3s ease;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  
  .sidebar.collapsed {
    width: 80px;
  }
  
  .sidebar-item {
    display: flex;
    align-items: center;
    padding: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s ease;
  }
  
  .sidebar-item.active, .sidebar-item:hover {
    background-color: #34495e;
  }
  
  .sidebar-item span {
    margin-left: 10px;
  }
  
  .content {
    flex: 1;
    padding: 20px;
    background-color: #ecf0f1;
    height: 100vh;
    overflow-y: auto;
  }
  
  @media (max-width: 768px) {
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
  
    .sidebar.collapsed {
      width: 100%;
    }
  }
  