.filter-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .filter-container input,
  .filter-container select {
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .filter-button {
    background-color: #6D31ED;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .filter-button:hover {
    background-color: #5a27bf;
  }
  