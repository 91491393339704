.signup-container {
    display: flex;
    width: 70vw;
    height: 85vh;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin: auto;
    justify-content: space-between;
  }
  
  .signup-image {
    flex: 1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .signup-form {
    flex: 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .signup-logo {
    width: 50px;
    margin-top: 3rem;
    animation: fadeIn 1s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @media screen and (max-width: 768px) {
    .signup-container {
      flex-direction: column;
      width: 90vw; 
      height: auto; 
    }
  
    .signup-image {
      height: 200px;
    }
  
    .signup-form {
      padding: 2rem;
    }
  
    .signup-logo {
      width: 40px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .signup-container {
      width: 95vw;
      height: auto;
    }
  
    .signup-form {
      padding: 1rem;
    }
  
    .signup-logo {
      width: 30px;
    }
  }
  