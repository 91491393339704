.app {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .sidebar {
    width: 200px;
    background-color: #f4f4f4;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
    overflow-y: auto;
  }
  
  .cards-container {
    flex: 1;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    overflow-y: auto;
  }
  
  .sidebar-item {
    margin-bottom: 10px;
    cursor: pointer;
    padding: 10px;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .sidebar-item:hover {
    background-color: #e0e0e0;
  }
  
  .card {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .card-icon {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .card-image {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
    border-radius: 8px;
    object-fit: cover;
  }
  
  h3 {
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  p {
    font-size: 0.9em;
    color: #555;
    margin-bottom: 20px;
  }
  
  .try-now-button {
    background-color: #6D31ED;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .try-now-button:hover {
    background-color: #5a27bf;
  }
  
  .arrow {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
  }
  
  .try-now-button:hover .arrow {
    transform: translateX(5px);
  }
  