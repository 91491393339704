.textBottomMargin{
    margin-bottom: 0px !important;
}

.chart-container {
  width: 100%; /* Full width of the parent container */
  height: 400px; /* Adjust height as desired */
}

.chart-container canvas {
  width: 100% !important;
  height: 100% !important;
}


