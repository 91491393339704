.signin-container {
    display: flex;
    width: 70vw;
    height: 85vh;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin: auto;
    justify-content: space-between;
    align-items: stretch;
  }
  
  .signin-image {
    flex: 1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border-radius 0.5s;
  }
  
  .signin-form {
    flex: 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
    transition: border-radius 0.5s;
  }
  
  .signin-logo {
    width: 50px;
    margin-top: 1rem;
    animation: fadeIn 1s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @media screen and (max-width: 768px) {
    .signin-container {
      flex-direction: column;
      width: 90vw;
      height: auto;
    }
  
    .signin-image {
      height: 200px;
      border-radius: 8px 8px 0 0;
    }
  
    .signin-form {
      padding: 2rem;
      border-radius: 0 0 8px 8px;
    }
  
    .signin-logo {
      width: 40px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .signin-container {
      width: 95vw;
      height: auto;
    }
  
    .signin-form {
      padding: 1rem;
    }
  
    .signin-logo {
      width: 30px;
    }
  }
  