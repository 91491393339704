.app {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .sidebar {
    width: 240px;
    background-color: #343a40;
    color: #fff;
    transition: width 0.3s;
    overflow-y: auto;
  }
  
  .sidebar.collapsed {
    width: 80px;
  }
  
  .sidebar-item {
    padding: 15px;
    cursor: pointer;
    border-bottom: 1px solid #495057;
    display: flex;
    align-items: center;
    transition: background-color 0.3s, padding 0.3s;
  }
  
  .sidebar.collapsed .sidebar-item {
    padding: 15px 10px;
    justify-content: center;
  }
  
  .sidebar-item:hover {
    background-color: #495057;
  }
  
  .sidebar-item span {
    margin-left: 10px;
  }
  
  .sidebar.collapsed .sidebar-item span {
    display: none;
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
    background-color: #f8f9fa;
    overflow-y: auto;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .content {
    flex: 1;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .dashboard-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .stats-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .stat-box {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .chart-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  canvas {
    width: 100%;
    height: 100%;
    max-width: 400px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
    .try-now-button {
    background-color: #6D31ED;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .try-now-button:hover {
    background-color: #5a27bf;
  }
  
  .arrow {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
  }
  
  .try-now-button:hover .arrow {
    transform: translateX(5px);
  }
  
  .crm-page {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }

  .export-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 20px;
  }
  
  .export-button:hover {
    background-color: #45a049;
  }
  
  .reports-container {
    margin-top: 20px;
  }
  
  .report-box {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .report-header {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .report-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .report-table th, .report-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .report-table th {
    background-color: #f4f4f4;
  }
  .integrations-container {
    margin-top: 20px;
  }
  
  .integration-box {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .integration-header {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .integration-description {
    font-size: 0.9em;
    color: #555;
  }
  
  .integration-list {
    list-style: none;
    padding: 0;
  }
  
  .integration-list-item {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
    background-color: #f4f4f4;
    transition: background-color 0.3s;
  }
  
  .integration-list-item:hover {
    background-color: #e0e0e0;
  }

.sidebar {
  width: 200px;
  height: 100%;
  background-color: #343a40;
  color: #fff;
  padding-top: 20px;
}

.sidebar-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebar-item:hover {
  background-color: #495057;
}

.content {
  flex: 1;
  padding: 20px;
  background-color: #f8f9fa;
}

.content h2 {
  color: #007bff;
  margin-bottom: 20px;
}

.export-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 10px;
}

.export-button:hover {
  background-color: #218838;
}

.chart-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.chart-box {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.chart-box canvas {
  width: 100%;
  height: 100%;
}
