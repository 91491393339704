.gridIcon {
  font-size: 60px;
  color: red;
}

button.supportButton {
  position: absolute;
  top: 180px;
  /* z-index: 2; */
  left: 350px;
}

img.bannerImage {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.featureWidget {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerHiring {
  text-align: center;
}

.footerHiring h1 {
  color: #e77777;
}
.containerBgImage {
  background-image: url('./SupportPageBackgroundImage.jpg');
  background-size: cover;
  background-position: right;
}
